
.f-f-p {
  font-family: "Poppins", sans-serif;
}

.text-color-gray {
  color: #3d3940;
}
.text-color-gray-light {
  color: #51545b;
}

.bg-card-gray {
  background: #edf2f7;
}
.bg-gray-light {
  background-color: #f7fafc;
}
.bg-purple-light {
  background-color: #e8e6fe;
}
.custom-height {
  min-height: 761.14px;
}
.container1 {
  padding-left: 35px;
  letter-spacing: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  cursor: pointer;
  border-radius: 6px;
}

.container1:hover input ~ .checkmark {
  background-color: white;
}

.container1 input:checked ~ .checkmark {
  background-color: #805ad5;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container1 input:checked ~ .checkmark:after {
  display: block;
}

.container1 .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container2 {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  font-size: 16px;
  color: white;
  letter-spacing: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  cursor: pointer;
  border-radius: 6px;
}

.container2:hover input ~ .checkmark {
  background: #e8e6fe;
}


.container2 input:checked ~ .checkmark {
  background: #e8e6fe;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container2 input:checked ~ .checkmark:after {
  display: block;
}

.container2 .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 11px;
  border: solid #805ad5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (min-width: 320px) and (max-width: 479px) {
  .custom-height {
      min-height: 400px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .custom-height {
      min-height: 600px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .custom-height {
      min-height: 350px;
  }
}
@media (min-width: 1024px) and (max-width: 1269px) {
  .custom-height {
      min-height: 550px;
  }
}
@media (min-width: 1270px) and (max-width: 1365px) {
  .custom-height {
      min-height: 750px;
  }
}

.tracking-1px {
  letter-spacing: 1px;
}
/* Nav */
.top-100 {
  animation: slideDown 0.5s ease-in-out;
}





@keyframes slideDown {
  0% {
      top: -50%;
  }
  100% {
      top: 0;
  }
}
* {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.section {

  @apply w-full p-6;
 
 }
 
 
 .section-title {
 
  @apply w-48 mx-auto pt-6 border-b-2 border-orange-500 text-center text-2xl text-blue-700;
 
 }
 
 
 .section-subtitle {
 
  @apply p-2 text-center text-lg text-gray-700;
 
 }
 .float{
	position:fixed;
	width:50px;
	height:50px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
